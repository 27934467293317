import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 94%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
  padding-top: 15vh;
  padding-bottom: 6vh;

  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text}88;
`;

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Content>© 2024 Jan Hamernik</Content>
    </Wrapper>
  );
};

export default Footer;
