import { PhotoData, PhotoListData } from "../types";

import Papa from "papaparse";
import axios from "axios";

export const extractGoogleImageIdFromUrl = (url: string): string | null => {
  const match = url.match(/\/d\/([a-zA-Z0-9_-]+)(?=\/|$)/);
  return match ? match[1] : null;
};

export const fetchCSVData_Photos: () => Promise<PhotoListData> = async () => {
  const csvUrl = process.env.REACT_APP_CSV_PHOTOS; // Google Sheets CSV file URL

  try {
    const response = await axios.get(csvUrl);
    const parsedData = parseCSV(response.data);

    return parsedData;
  } catch (error) {
    console.error("Error fetching CSV data:", error);
  }
  return null;
};

export const parseCSV = (csvText: String) => {
  let data: PhotoListData = null;

  try {
    const rowsRaw = Papa.parse(csvText).data;
    const photos: PhotoData[] = [];
    let photosEnvironment: string = null;

    rowsRaw.forEach((rowRaw, i) => {
      const rowIsEmpty = rowRaw.every((element) => element === "");

      if (!rowIsEmpty) {
        if (i === 0) {
          // first row = environment name
          photosEnvironment = rowRaw[0].trim();
          return;
        }

        if (i === 1) {
          // second row = table header
          return;
        }

        const imageUrlRaw = rowRaw[3];
        if (!imageUrlRaw?.length || !imageUrlRaw.includes("http")) {
          // row is not a valid image definition
          return;
        }

        const imageId = extractGoogleImageIdFromUrl(imageUrlRaw);
        let thumbnailUrl;
        let imageUrl;

        if (imageId) {
          thumbnailUrl = `https://drive.google.com/thumbnail?id=${imageId}&sz=w1000`;
          imageUrl = `https://drive.google.com/thumbnail?id=${imageId}&sz=w1600`;
        } else {
          thumbnailUrl = imageUrlRaw;
          imageUrl = imageUrlRaw;
        }

        photos.push({
          name: rowRaw[0],
          description: rowRaw[1],
          image: {
            imageUrl,
            thumbnailUrl,
            width: rowRaw[2]?.length ? parseInt(rowRaw[2]) : 1,
            height: 1,
          },
        });
      }
    });

    data = {
      photos,
      photosEnvironment,
    };
  } catch (error) {
    console.error("Error parsing CSV data:", error);
  }

  return data;
};
