import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";

import ImageText from "components/ImageText";
import ScrollIndicator from "components/ScrollIndicator";

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.XS}) {
    padding-top: 5vh;
  }
`;

const TextWrapper = styled.div`
  position: relative;

  --hero-font-size: min(400px, 18vw);

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.L}) {
    --hero-font-size: min(400px, 20vw);
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.M}) {
    --hero-font-size: min(400px, 25vw);
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.XS}) {
    --hero-font-size: min(400px, 30vw);
  }
`;

const TextTop_LoadAnimation = keyframes`
 from {
  transform: translateY(-20px);
  opacity: 0;
 }
`;

const TextTop = styled.div`
  position: absolute;

  top: calc(var(--hero-font-size) * 0.075);
  left: 51.4%;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.M}) {
    //top: calc(var(--hero-font-size) * 0.18);
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.S}) {
    top: calc(var(--hero-font-size) * 0.045);
  }

  font-size: calc(var(--hero-font-size) * 0.1);
  font-weight: 400;
  line-height: 2em;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.text};
  opacity: 0.7;

  animation-name: ${TextTop_LoadAnimation};
  animation-timing-function: ease;
  animation-fill-mode: backwards;
  animation-duration: 0.6s;
  animation-delay: 0.4s;
`;

const TextHero_LoadAnimation = keyframes`
 from {
  transform: translateY(20px);
  opacity: 0;

 }
`;
const TextHeroBackground_LoadAnimation = keyframes`
 from {
  background-position: center calc(50% + var(--background-vertical-offset) - 15px);

 }
`;

const TextHero = styled.div`
  --anim-timing-func: ease;
  --anim-fill-mode: backwards;
  --anim-duration: 0.6s;
  --anim-delay: 0.2s;

  animation-name: ${TextHero_LoadAnimation};
  animation-timing-function: var(--anim-timing-func);
  animation-fill-mode: var(--anim-fill-mode);
  animation-duration: var(--anim-duration);
  animation-delay: var(--anim-delay);

  & > div > div {
    animation-name: ${TextHeroBackground_LoadAnimation};
    animation-timing-function: var(--anim-timing-func);
    animation-fill-mode: var(--anim-fill-mode);
    animation-duration: var(--anim-duration);
    animation-delay: var(--anim-delay);
  }

  & > div {
    --font-size: var(--hero-font-size);
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 3vh;
  margin-bottom: min(70px, 5vh);

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.XS}) {
    padding-top: 5vh;
  }
`;

const ContactItem_LoadAnimation = keyframes`
 from {
  transform: translateY(20px);
  opacity: 0;
 }
`;

const ContactItem = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text}66;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.XS}) {
    font-size: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      font-weight: 500;
    }
  }

  animation-name: ${ContactItem_LoadAnimation};
  animation-timing-function: ease;
  animation-fill-mode: backwards;
  animation-duration: 0.5s;

  &:nth-child(1) {
    animation-delay: 0.6s;
  }
  &:nth-child(2) {
    animation-delay: 0.8s;
  }
`;

const Intro: React.FC = () => {
  const [waitForScrollIndicator, setWaitForScrollIndicator] = useState(true);
  const [scrollIndicatorIsVisible, setScrollIndicatorIsVisible] =
    useState(true);

  const timerRef = useRef<any>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setWaitForScrollIndicator(false), 2000);

    const handleScroll = () => {
      if (window?.scrollY > 150) {
        setScrollIndicatorIsVisible(false);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <TextTop>Fotografka</TextTop>
          <TextHero>
            <ImageText text="Sára" />
          </TextHero>
        </TextWrapper>

        <Contact>
          <ContactItem>{process.env.REACT_APP_EMAIL}</ContactItem>
          <ContactItem>
            <a
              href={process.env.REACT_APP_INSTAGRAM}
              target="_blank"
              rel="noreferrer"
            >
              instagram
            </a>
          </ContactItem>
        </Contact>

        <ScrollIndicator
          isVisible={!waitForScrollIndicator && scrollIndicatorIsVisible}
        />
      </Content>
    </Wrapper>
  );
};

export default Intro;
