import { encodePassword, isValidPassword, savePassword } from "utils/auth";

import styled from "styled-components";
import { useState } from "react";

const Wrapper = styled.div`
  flex: 1;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  width: 94%;
  max-width: 300px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const Input = styled.input`
  align-self: stretch;
  outline: none;
  border: none;

  background: ${(props) => props.theme.colors.text}11;
  color: ${(props) => props.theme.colors.text};
  border-radius: 2px;
  padding: 15px;

  font-family: "Roboto", sans-serif;
  font-size: 16px;
`;

const Submit = styled.button`
  outline: none;
  border: none;
  cursor: pointer;

  background: ${(props) => props.theme.colors.text}11;
  color: ${(props) => props.theme.colors.text}88;
  border-radius: 2px;
  padding: 5px 10px;

  font-family: "Roboto", sans-serif;
  font-size: 14px;

  &:hover {
    background: ${(props) => props.theme.colors.text}22;
    color: ${(props) => props.theme.colors.text}88;
  }
`;

const LoginForm = (props: { onLogin: () => void }) => {
  const [password, setPassword] = useState("");

  const onSubmit = () => {
    if (isValidPassword(password)) {
      savePassword(encodePassword(password));
      props.onLogin();
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <Input
          type="password"
          placeholder="heslo"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Submit type="submit">Přihlásit se</Submit>
      </Form>
    </Wrapper>
  );
};

export default LoginForm;
