import "./index.css";

import { GlobalStyles, theme } from "./styles";
import styled, { ThemeProvider } from "styled-components";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";

const Root = styled.div`
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyles />

    <ThemeProvider theme={theme}>
      <Root>
        <App />
      </Root>
    </ThemeProvider>
  </React.StrictMode>
);
