import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
html,
body {
    padding: 0;
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    //font-family: 'Roboto', sans-serif;
    font-family: "Barlow", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4em;
}

`;

export const theme = {
  colors: {
    background: "#ECECEC",
    text: "#000000",
    color: "#90AFBA",
  },
  breakpoints: {
    L: "1200px",
    M: "900px",
    S: "640px",
    XS: "560px",
  },
};
