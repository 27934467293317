import { useEffect, useRef, useState } from "react";

import Background from "assets/background_intro.jpg";
import styled from "styled-components";

const Wrapper = styled.div`
  --font-size: min(
    400px,
    25vw
  ); // THIS IS USUALLY OVERRIDDEN FROM THE OUTSIDE TO HANDLE BREAKPOINTS AND CUSTOM SIZES

  position: relative;

  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--font-size);
  font-weight: 800;
  line-height: 1.3em;
  text-transform: uppercase;
  text-align: center;
`;

const TextRaw = styled.div`
  color: ${(props) => props.theme.colors.color}55;
`;

const TextWithImage = styled.div<{ $imageLoaded: boolean }>`
  --background-vertical-offset: 0px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: url(${Background});
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center calc(50% + var(--background-vertical-offset));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  opacity: ${(props) => (props.$imageLoaded ? 1 : 0)};
  transition: opacity 1s ease;
`;

const ImageText = (props: { text: string; fontSizeCSS?: any }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const scrollY = window.scrollY;

        ref.current.style.setProperty(
          "--background-vertical-offset",
          `${scrollY * 0.15}px`
        );
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  useEffect(() => {
    const img = new Image();
    img.src = Background;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <Wrapper>
      <TextRaw>{props.text}</TextRaw>
      <TextWithImage ref={ref} $imageLoaded={imageLoaded}>
        {props.text}
      </TextWithImage>
    </Wrapper>
  );
};

export default ImageText;
