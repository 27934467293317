import styled, { keyframes } from "styled-components";

const Wrapper = styled.div<{ $isVisible: boolean }>`
  --width: 20px;
  --height: 60px;
  --circle: 15px;

  position: relative;
  display: flex;
  justify-content: center;

  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);
  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);

  border-radius: calc(var(--width) / 2);
  border: 1px solid ${(props) => props.theme.colors.color}55;

  transition: 0.4s ease all;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
`;

const CircleAnimation = keyframes`
  to {  transform: translateY(
    calc(var(--height) - var(--circle) * 1.5)
  );
  }
`;

const Circle = styled.div`
  position: absolute;

  top: calc((var(--width) - var(--circle)) / 2);

  width: var(--circle);
  min-width: var(--circle);
  max-width: var(--circle);
  height: var(--circle);
  min-height: var(--circle);
  max-height: var(--circle);

  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.color}AA;

  animation: ${CircleAnimation} 2s ease infinite;
`;

const ScrollIndicator = (props: { isVisible?: boolean }) => {
  return (
    <Wrapper
      $isVisible={props.isVisible === true || props.isVisible === undefined}
    >
      <Circle />
    </Wrapper>
  );
};

export default ScrollIndicator;
