import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  padding-top: 20vh;
  padding-bottom: 40vh;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

const LoadAnimation = keyframes`
      0%,
    40%,
    100% {
      transform: scaleY(0.05);
      -webkit-transform: scaleY(0.05);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
`;

const LoaderWrapper = styled.div`
  height: 100px;

  > div {
    height: 100%;
    width: 12px;
    display: inline-block;

    -webkit-animation: ${LoadAnimation} 0.8s infinite ease-in-out;
    animation: ${LoadAnimation} 0.8s infinite ease-in-out;

    &:nth-child(1) {
      background-color: #754fa0;
    }
    &:nth-child(2) {
      background-color: #09b7bf;
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
    &:nth-child(3) {
      background-color: #90d36b;
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
    &:nth-child(4) {
      background-color: #f2d40d;
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    &:nth-child(5) {
      background-color: #fcb12b;
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    &:nth-child(6) {
      background-color: #ed1b72;
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }
`;

const Loader = () => {
  return (
    <Wrapper>
      <Content>
        <LoaderWrapper>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LoaderWrapper>
        vteřinku než se vyvolají fotky...
      </Content>
    </Wrapper>
  );
};

export default Loader;
