import { getSavedPassword, isValidEncodedPassword, requiresAuth } from "utils";
import { useEffect, useState } from "react";

import EnvWarning from "components/EnvWarning";
import Footer from "components/Footer";
import Gallery from "components/Gallery";
import Intro from "components/Intro";
import LayoutMain from "layouts/LayoutMain";
import Loader from "components/Loader";
import LoginForm from "components/LoginForm";
import { PhotoListData } from "./types";
import { fetchCSVData_Photos } from "utils";

const App = () => {
  let [isAuthorized, setIsAuthorized] = useState<boolean>(!requiresAuth());
  let [data, setData] = useState<PhotoListData>();

  useEffect(() => {
    const savedPassword = getSavedPassword();
    if (savedPassword && isValidEncodedPassword(savedPassword)) {
      onLogin();
    }
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      let asyncFunc = async () => {
        setData(await fetchCSVData_Photos());
      };
      asyncFunc();
    }
  }, [isAuthorized]);

  const onLogin = () => {
    setIsAuthorized(true);
  };

  return !isAuthorized ? (
    <LayoutMain>
      <LoginForm onLogin={onLogin} />
    </LayoutMain>
  ) : (
    <LayoutMain>
      <Intro />
      {!data ? <Loader /> : <Gallery images={data?.photos} />}
      <Footer />
      <EnvWarning photosEnvironment={data?.photosEnvironment} />
    </LayoutMain>
  );
};

export default App;
