import "yet-another-react-lightbox/styles.css";

import { useMemo, useState } from "react";

import Lightbox from "yet-another-react-lightbox";
import { PhotoData } from "types";
import { Gallery as ReactGridGallery } from "react-grid-gallery";
import styled from "styled-components";
import { theme } from "styles";

const Wrapper = styled.div``;

const Content = styled.div`
  .ReactGridGallery_tile {
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.02);
    }

    .ReactGridGallery_tile-viewport {
      @media only screen and (max-width: ${(props) => props.theme.breakpoints.L}) {
        height: 250px !important;
      }
      @media only screen and (max-width: ${(props) => props.theme.breakpoints.XS}) {
        height: 200px !important;
      }

      & > * {
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
`;

const Gallery = (props: { images?: PhotoData[] }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(undefined);

  const images = useMemo(() => {
    return (props.images || [])?.map((img) => {
      return {
        src: img.image.imageUrl,
        thumbnail: img.image.thumbnailUrl,
        width: img.image.width,
        height: img.image.height,
        alt: img.name,
        isSelected: false,
      };
    });
  }, [props.images]);

  const onImageClick = (imgIndex: number) => {
    setActiveImageIndex(imgIndex);
  };

  return (
    <Wrapper>
      <Content>
        <ReactGridGallery
          images={images?.map((img) => {
            return { ...img, src: img.thumbnail };
          })}
          enableImageSelection={false}
          margin={6}
          rowHeight={300}
          thumbnailStyle={
            {
              /* IMPORTANT */
            }
          }
          onClick={onImageClick}
        />
      </Content>

      <Lightbox
        open={activeImageIndex !== undefined}
        index={activeImageIndex}
        close={() => setActiveImageIndex(undefined)}
        slides={props.images?.map((img) => {
          return {
            src: img.image.imageUrl,
            alt: img.name,
          };
        })}
        controller={{ closeOnBackdropClick: true }}
        styles={{
          container: { backgroundColor: `${theme.colors.background}EE` },
          icon: {
            color: `${theme.colors.text}`,
          },
          button: {
            filter: "none",
          },
        }}
      />
    </Wrapper>
  );
};

export default Gallery;
