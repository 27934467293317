import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: calc(94%);
  max-width: 1400px;
`;

const LayoutMain = (props?: { children?: any }) => {
  return (
    <Wrapper>
      <Content>{props?.children}</Content>
    </Wrapper>
  );
};

export default LayoutMain;
