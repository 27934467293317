import styled from "styled-components";
import { useState } from "react";

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  left: 20px;
  background: ${(props) => props.theme.colors.background};
`;

const Content = styled.div`
  background: #990000;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Labels = styled.div`
  padding: 5px;
  padding-right: 12px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.background};
  font-size: 10px;
  font-weight: 600;
`;

const CloseButton = styled.div`
  cursor: pointer;
  padding: 12px 16px;

  color: ${(props) => props.theme.colors.background};

  & > div {
    font-size: 16px;
    font-weight: 400;
    transform: scaleX(1.4);
  }
`;

const EnvWarning = (props: { photosEnvironment: string }) => {
  const [isOpen, setIsOpen] = useState(true);

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isProduction = environment === "PRODUCTION";

  const photosEnvironment = props.photosEnvironment;
  const photosIsLoading = photosEnvironment === undefined;
  const photosIsProduction = photosEnvironment === "PRODUCTION";

  const showWarning =
    !isProduction || (!photosIsLoading && !photosIsProduction);

  return isOpen && showWarning ? (
    <Wrapper>
      <Content>
        <CloseButton onClick={() => setIsOpen(false)}>
          <div>X</div>
        </CloseButton>

        <Labels>
          {!photosIsLoading && !photosIsProduction ? (
            <Label>{`Photos: ${photosEnvironment}`}</Label>
          ) : null}

          {!isProduction ? (
            <Label>{`Env: ${process.env.REACT_APP_ENVIRONMENT}`}</Label>
          ) : null}
        </Labels>
      </Content>
    </Wrapper>
  ) : null;
};

export default EnvWarning;
